<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import {calculatorPricingSettings, dayTemplates, pricingSettings, updatePricingIfEmpty} from "@shared/Form/PricingSelectionType/store"
    import RangeSlider from "svelte-range-slider-pips/src/RangeSlider.svelte"
    import {Price} from "@utils/Price"
    import HiddenType from "@shared/Form/HiddenType/HiddenType.svelte"
    import {onMount} from "svelte"
    import CheckboxType from "@shared/Form/CheckboxType/CheckboxType.svelte"
    import tooltip from "@utils/Action/Tooltip"
    import Translator from "bazinga-translator";

    export let entry: FormEntry<any, { minDiaryCount: number, lockDays: boolean, discount: number | null, originalPrice: number | null }>

    let minDiaryCount = entry.extra.minDiaryCount
    let infiniteDiaries = entry.children["infinite"].checked
    let templateIndex = entry.children["days"].value !== "" ? 0 : 2
    let daySliderValue = entry.children["days"].value !== "" ? parseInt(entry.children["days"].value) : null
    let diarySliderValue = entry.children["diaries"].value !== "" ? parseInt(entry.children["diaries"].value) : 12
    let price: number | null
    let priceVat: number | null
    let minDays = entry.children["days"].value !== "" ? parseInt(entry.children["days"].value) : 7

    let maxDiaries = 200

    $: {
        if (Object.keys($calculatorPricingSettings).length > 0) {
            maxDiaries = Object.keys($calculatorPricingSettings).length
        }
    }

    $: dayTemplateName = (dayTemplates[templateIndex]?.name ?? Translator.trans("custom")).toLowerCase()

    $: {
        if (dayTemplates[templateIndex].day != null) {
            daySliderValue = dayTemplates[templateIndex].day
        }
    }

    const handleDaySliderChange = (e: CustomEvent<{ values: number[] }>): void => {
        templateIndex = 0
        daySliderValue = e.detail.values[0]
    }

    const handleDiaryCountScroll = (e: WheelEvent): void => {
        if (infiniteDiaries) {
            return
        }

        templateIndex = 0

        const val = e.shiftKey ? 5 : 1
        if (e.deltaY > 0) {
            diarySliderValue -= val
        } else if (e.deltaY < 0) {
            diarySliderValue += val
        }
    }

    const handleDayCountScroll = (e: WheelEvent): void => {
        if (entry.extra.lockDays) {
            return
        }

        templateIndex = 0

        const val = e.shiftKey ? 10 : 1
        if (e.deltaY > 0) {
            daySliderValue -= val
        } else if (e.deltaY < 0) {
            daySliderValue += val
        }
    }

    $: {
        if (daySliderValue > 365) {
            daySliderValue = 365
        }

        if (daySliderValue < minDays) {
            daySliderValue = minDays
        }
    }

    $: {
        if (diarySliderValue > maxDiaries) {
            diarySliderValue = maxDiaries
        }

        if (diarySliderValue < minDiaryCount) {
            diarySliderValue = minDiaryCount
        }
    }

    const calculateInfiniteDiaryPrices = (): void => {
        let priceNoVat = $pricingSettings.priceForInfiniteDiariesForDay * daySliderValue

        price = priceNoVat
        priceVat = priceNoVat * 1.21
    }

    const handleInfiniteToggle = (e: CustomEvent<boolean>): void => {
        infiniteDiaries = e.detail
        if (!infiniteDiaries && $pricingSettings.priceForInfiniteDiariesForDay !== null) {
            const diarySliderValues = Object.values($calculatorPricingSettings)
            for (let i = 0; i < diarySliderValues.length; i++) {
                if (diarySliderValues[i].pricePerDiaryDay >= $pricingSettings.priceForInfiniteDiariesForDay) {
                    diarySliderValue = i - 15
                    break
                }
            }
        }
    }

    $: {
        if (infiniteDiaries) {
            calculateInfiniteDiaryPrices()
        } else if (!$calculatorPricingSettings[diarySliderValue]) {
            price = null
            priceVat = null
        } else {
            if ($pricingSettings.priceForInfiniteDiariesForDay !== null && $calculatorPricingSettings[diarySliderValue].pricePerDiaryDay >= $pricingSettings.priceForInfiniteDiariesForDay) {
                infiniteDiaries = true
                entry.children["infinite"].checked = true

                calculateInfiniteDiaryPrices()
            } else {
                let priceNoVat = $calculatorPricingSettings[diarySliderValue].pricePerDiaryDay * daySliderValue

                price = priceNoVat
                priceVat = priceNoVat * 1.21
            }
        }
    }

    $: {
        entry.children["diaries"].value = diarySliderValue as string
        entry.children["days"].value = daySliderValue as string
    }

    onMount(() => updatePricingIfEmpty())
</script>


<div class="flex flex-col">
    <div class="flex flex-col mb-12">
        <div class="mb-5">
            <h3 class="text-3xl font-bold">{Translator.trans("pricing_selection.title")}</h3>
            {#if entry.extra.lockDays}
                <p class="text-sm mt-2">{Translator.trans("pricing_selection.sub_title")}</p>
            {/if}
        </div>

        <div class="flex flex-wrap gap-y-6 gap-x-12 mb-7">
            {#each dayTemplates as template, index (template.name)}
                <div class="msd-pricing-checkbox">
                    <input type="radio"
                           class="hidden"
                           name="duration"
                           id="pt_dt_{index}"
                           checked={templateIndex === index ? "checked" : null}
                           on:change={(e) => e.target.checked ? (templateIndex = index) : undefined}
                    >
                    <label for="pt_dt_{index}">
                        <span></span>
                        <span>{template.name}</span>
                    </label>
                </div>
            {/each}
        </div>

        <div class="flex flex-row gap-6">
            <div class="flex flex-row items-center flex-1">
                <button class="bg-primary p-1 rounded-md" class:bg-neutral-200={entry.extra.lockDays} on:click|preventDefault={() => !entry.extra.lockDays && daySliderValue--}>
                    <img src="/image/slider-step-down.svg" alt="step up "/>
                </button>

                <RangeSlider
                        min={minDays}
                        max={365}
                        range="min"
                        values={[daySliderValue]}
                        on:change={handleDaySliderChange}
                        disabled={entry.extra.lockDays}
                />

                <button class="bg-primary p-1 rounded-md" class:bg-neutral-200={entry.extra.lockDays} on:click|preventDefault={() => !entry.extra.lockDays && daySliderValue++}>
                    <img src="/image/slider-step-up.svg" alt="step up "/>
                </button>
            </div>

            <div class="flex flex-row gap-2 items-center">
                <input bind:value={daySliderValue}
                       disabled={entry.extra.lockDays}
                       class="bg-white rounded-md shadow-sm px-4 py-2 focus-visible:outline-0 focus-visible:shadow-md w-24 text-center"
                       class:bg-neutral-200={entry.extra.lockDays}
                       type="text"
                       on:wheel|preventDefault={handleDayCountScroll}
                />
                <span class="w-12">
                    {#if daySliderValue === 1}
                        {Translator.trans("day_count.one")}
                    {:else if daySliderValue < 5}
                        {Translator.trans("day_count.less_than_5")}
                    {:else}
                        {Translator.trans("day_count.more")}
                    {/if}
                </span>
            </div>
        </div>
    </div>

    <div class="flex flex-col mb-8">
        <h3 class="mb-5 text-3xl font-bold">{Translator.trans("pricing_selection.diaries")}</h3>

        <div class="flex flex-row gap-6">
            <div class="flex flex-row items-center flex-1">
                <button class="bg-primary p-1 rounded-md" class:bg-neutral-200={infiniteDiaries} on:click|preventDefault={() => !infiniteDiaries && diarySliderValue--}>
                    <img src="/image/slider-step-down.svg" alt="step up "/>
                </button>

                <RangeSlider
                        min={minDiaryCount}
                        max={maxDiaries}
                        range="min"
                        values={[diarySliderValue]}
                        on:change={e => diarySliderValue = e.detail.values[0]}
                        disabled={infiniteDiaries}
                />

                <button class="bg-primary p-1 rounded-md" class:bg-neutral-200={infiniteDiaries} on:click|preventDefault={() => !infiniteDiaries && diarySliderValue++}>
                    <img src="/image/slider-step-up.svg" alt="step up "/>
                </button>
            </div>

            <div class="flex flex-row gap-2 items-center">
                <input bind:value={diarySliderValue}
                       disabled={infiniteDiaries}
                       class="bg-white rounded-md shadow-sm px-4 py-2 focus-visible:outline-0 focus-visible:shadow-md w-24 text-center"
                       class:bg-neutral-200={infiniteDiaries}
                       type="text"
                       on:wheel|preventDefault={handleDiaryCountScroll}
                />
                <span class="w-12">
                    {#if diarySliderValue === 1}
                        {Translator.trans("diary_count.one")}
                    {:else if diarySliderValue < 5}
                        {Translator.trans("diary_count.less_than_5")}
                    {:else}
                        {Translator.trans("diary_count.more")}
                    {/if}
                </span>
            </div>
        </div>
    </div>

    <div class="flex flex-col mb-6 gap-6">
        <CheckboxType entry={entry.children["infinite"]} on:toggle={handleInfiniteToggle}/>

        {#if entry.children["recurring"]}
            <div class="max-w-md">
                <CheckboxType entry={entry.children["recurring"]}/>
            </div>
        {/if}
    </div>

    {#if entry.extra.discount === null}
        <div class="flex flex-row bg-white rounded-md shadow-md px-8 py-3 items-center text-sm lg:text-lg min-h-[60px]">
            {#if priceVat === null && price === null}
                <span>{Translator.trans("pricing_selection.calculating")}</span>
            {:else}
                <span>{Translator.trans("pricing_selection.final_price")}</span>
                <span class="lg:text-3xl font-bold pl-2">
                    {Price.default().format(price, false, true)}<br class="inline lg:hidden">
                    ({Price.default().format(priceVat, true, true)})
                </span>
                <span><span class="px-1">/</span>{dayTemplateName}</span>
            {/if}
        </div>
    {:else}
        <div class="flex flex-row bg-white rounded-md shadow-md px-8 py-3 items-center text-sm lg:text-lg min-h-[60px] mb-4">
            {#if priceVat === null && price === null}
                <span>{Translator.trans("pricing_selection.calculating")}</span>
            {:else}
                <span>{Translator.trans("pricing_selection.discount_for_unused")}</span>
                <span class="lg:text-xl font-bold pl-2">
                    {Price.default().format(entry.extra.discount, false, true)}<br class="inline lg:hidden">
                    ({Price.default().format(entry.extra.discount * 1.21, true, true)})
                </span>
                <span><span class="px-1">/</span>{dayTemplateName}</span>
            {/if}
        </div>

        <div class="flex flex-row bg-white rounded-md shadow-md px-8 py-3 items-center text-sm lg:text-lg min-h-[60px] mb-3">
            {#if priceVat === null && price === null}
                <span>{Translator.trans("pricing_selection.calculating")}</span>
                <span>{Translator.trans("pricing_selection.increase")}</span>
            {:else}
                <span>{Translator.trans("pricing_selection.increase")}</span>
                <span class="lg:text-3xl font-bold pl-2">
                    {Price.default().format(price - entry.extra.discount, false, true)}<br class="inline lg:hidden">
                    ({Price.default().format(priceVat - (entry.extra.discount * 1.21), true, true)})
                </span>
                <span><span class="px-1">/</span>{dayTemplateName}</span>
            {/if}
        </div>

        <span class="text-sm italic">{Translator.trans("pricing_selection.price_increase_warning")}</span>
    {/if}

    <HiddenType entry={entry.children["diaries"]}/>
    <HiddenType entry={entry.children["days"]}/>
</div>

<style>
    div :global(.rangeSlider.focus .rangeNub) {
        @apply bg-primary !important;
    }

    div :global(.rangeSlider .rangeNub) {
        height: 44px;
        width: 20px;
        border-radius: 5px;
        top: -1px;
        @apply -translate-y-[13px] !important;
        @apply bg-primary;
    }

    div :global(.rangeSlider.focus .rangeBar) {
        @apply bg-primary !important;
    }

    div :global(.rangeSlider .rangeHandle:before) {
        display: none;
    }

    div :global(.rangeSlider) {
        @apply w-full drop-shadow-md;
    }

    div :global(.rangeSlider .rangeBar) {
        top: -2px;
        height: 5px;
        border-radius: 3px;
        @apply bg-primary;
    }

    div :global(.rangeSlider.range) {
        height: 3px;
        border-radius: 2px;
        @apply bg-neutral-500;
    }

    div :global(.rangeSlider.disabled .rangeNub) {
        @apply bg-neutral-200 !important;
    }

    div :global(.rangeSlider.range.min .rangeNub) {
        border-radius: 10em !important;
    }

    div :global(.rangeSlider.disabled .rangeBar) {
        @apply bg-neutral-600 !important;
        right: 100% !important;
    }
</style>